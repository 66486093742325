import VueRouter from 'vue-router'
import Preferences from './components/Preferences.vue'
import Notifications from './components/Notifications.vue'
import NotificationDetails from "./components/NotificationDetails.vue"
import SinglePageFeedback from "./components/SinglePageFeedback.vue"
import ThankYou from "./components/ThankYou.vue"

export const beforeEach = (to, from, next) => {
    localStorage.setItem('lastPath', to.path)
    next()
}

const preferencesWidgetURL = process.env.VUE_APP_PREFERENCES_WIDGET_URL
const inboxWidgetUrl = process.env.VUE_APP_INBOX_WIDGET_URL
const NewFeedbackUrl = process.env.VUE_APP_NEW_FEEDBACK_URL

export const routes = [
    {
        path: '/',
        component: Preferences,
        beforeEnter() {
            window.location.href = preferencesWidgetURL
        }
    },
    {
        path: '/preferences',
        component: Preferences,
        beforeEnter() {
            window.location.href = preferencesWidgetURL
        }
    },
    {
        path: '/preferences/:pin',
        component: Preferences,
        beforeEnter() {
            window.location.href = preferencesWidgetURL
        }
    },
    {
        path: '/notifications',
        component: Notifications,
        beforeEnter() {
            window.location.href = inboxWidgetUrl
        }
    },
    {
        path: "/notifications/:notificationId",
        component: NotificationDetails,
        // eslint-disable-next-line no-unused-vars
        beforeEnter(to, from, next) {
            const notificationId = to.params.notificationId
            window.location.href = inboxWidgetUrl + '/' + notificationId
        }
    },
    {
        path: '/login_success',
        redirect: () => {
            return localStorage.getItem('lastPath') ? localStorage.getItem('lastPath') : '/'
        }
    },
    {
        path: '/feedback',
        component: SinglePageFeedback,
        props: {
            crumbs: [
                {label: 'Home'},
            ],
            header: 'Customer feedback'
        },
        beforeEnter() {
            let uri = window.location.search.substring(1);
            window.location.href = NewFeedbackUrl + '?' + uri
        },
    },
    {
        path: '/thankyou',
        component: ThankYou,
        props: {
            crumbs: [
                {label: 'Home'},
            ],
            header: 'Customer feedback'
        }
    }
]

export const router = new VueRouter({
    mode: 'history',
    routes: routes,
    scrollBehavior:()=>({ x: 0, y: 0 })
})

router.beforeEach((to, from, next) => beforeEach(to, from, next))
